import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { categoryType, mediaType, sieveType, themeType } from "../../types";
import MediaSieve from "../MediaSieve";
import PaginationWrapper from "../PaginationWrapper";
import { normalize, stripPunctuation } from "../../../../shared/helpers";
import MediaGrid from "../MediaGrid";

const filterMedia = (media, filter) => {
  const getIds = (item) => item.id;

  return media.filter((m) => {
    if (filter.title) {
      const mediaTitleCompareString = normalize(stripPunctuation(m.title))
        .split(" ")
        .join("");
      const filterTitleCompareString = normalize(
        stripPunctuation(filter.title).split(" ").join("")
      );

      if (!mediaTitleCompareString.includes(filterTitleCompareString))
        return false;
    }

    if (filter.themes.length > 0) {
      if (m.themes.length === 0) return false;

      const mediaThemeIds = m.themes.map(getIds);
      const filterThemeIds = filter.themes.map(getIds);
      const doesNotIncludeThemes = !filterThemeIds.every((id) =>
        mediaThemeIds.includes(id)
      );
      if (doesNotIncludeThemes) return false;
    }

    if (filter.category) {
      if (!m.category) return false;
      if (m.category.id !== filter.category.id) return false;
    }

    return true;
  });
};

const sortMedia = (media, sort) => {
  const { prop, dir } = sort;
  const compareMedia = (media1, media2) => {
    if (media1[prop] < media2[prop]) return dir === "ASC" ? -1 : 1;
    if (media1[prop] > media2[prop]) return dir === "ASC" ? 1 : -1;
    return 0;
  };

  if (dir !== "DESC" && dir !== "ASC") return media;

  return [...media].sort(compareMedia);
};

function MediaSearch({
  media,
  themes,
  categories,
  perPage = 21,
  initialSieve,
}) {
  const [sievedMedia, setSievedMedia] = useState(media);

  const handleSieveChange = useCallback(({ filter, sort }) => {
    setSievedMedia(sortMedia(filterMedia(media, filter), sort));
    window.history.replaceState({ filter, sort }, "", "");
  });

  return (
    <>
      <Typography variant="h5" component="div">
        Results: {sievedMedia.length}
      </Typography>

      <MediaSieve
        initialSieve={initialSieve}
        onChange={handleSieveChange}
        themeOptions={themes}
        categoryOptions={categories}
      />

      <PaginationWrapper
        render={(paginatedData) => <MediaGrid media={paginatedData} />}
        data={sievedMedia}
        perPage={perPage}
      />
    </>
  );
}

MediaSearch.defaultProps = {
  media: [],
  themes: [],
  categories: [],
  perPage: 21,
  initialSieve: null,
};

MediaSearch.propTypes = {
  media: PropTypes.arrayOf(mediaType),
  themes: PropTypes.arrayOf(themeType),
  categories: PropTypes.arrayOf(categoryType),
  perPage: PropTypes.number,
  initialSieve: sieveType,
};

export default MediaSearch;
