import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { categoryType, sieveType, themeType } from "../../types";
import useDebounce from "../../hooks/useDebounce";
import useSieve from "../../hooks/useSieve";

const INITIAL_STATE = {
  filter: {
    title: "",
    themes: [],
    category: null,
  },
  sort: {
    prop: "publishedAt",
    dir: "DESC",
  },
};

const isFilterEmpty = (filter) =>
  filter.title === "" && filter.themes.length === 0 && filter.category === null;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: theme.spacing(3),
  },
  controlsHeading: {
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const compareSelected = (option, value) => option.id === value.id;

function MediaSieve({ themeOptions, categoryOptions, onChange, initialSieve }) {
  const debouncedOnChange = useDebounce(onChange);
  const { sieve, updateFilter, updateSort, reset } = useSieve(INITIAL_STATE);

  const handleFilterChange = (event, name, value) => {
    updateFilter({ [name]: value });
  };

  const handleSortChange = () => {
    updateSort({
      prop: "publishedAt",
      dir: sieve.sort.dir === "ASC" ? "DESC" : "ASC",
    });
  };

  const handleClearSieve = () => {
    reset();
  };

  useEffect(() => {
    if (initialSieve.filter) {
      updateFilter(initialSieve.filter);
    }
    if (initialSieve.sort) {
      updateSort(initialSieve.sort);
    }
  }, [initialSieve]);

  useEffect(() => {
    debouncedOnChange(sieve);
  }, [sieve.filter, sieve.sort]);

  const classes = useStyles();

  return (
    <form noValidate autoComplete="off" className={classes.root}>
      <div className={classes.controlsHeading}>
        <div>
          <Typography variant="body1" component="span">
            Sort:{" "}
          </Typography>
          <Button
            aria-label="sort by date published"
            title="Sort by date published"
            endIcon={
              sieve.sort.dir === "ASC" ? (
                <ArrowUpwardIcon />
              ) : (
                <ArrowDownwardIcon />
              )
            }
            onClick={handleSortChange}
          >
            Date Published
          </Button>
        </div>
        {!isFilterEmpty(sieve.filter) && (
          <Button
            size="small"
            color="secondary"
            aria-label="clear all filters"
            title="Clear all filters"
            onClick={handleClearSieve}
            startIcon={<ClearIcon />}
          >
            Clear All
          </Button>
        )}
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md>
          <TextField
            fullWidth
            id="titleSearch"
            variant="filled"
            label="Title"
            value={sieve.filter.title}
            onChange={(event) => {
              handleFilterChange(event, "title", event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md>
          <Autocomplete
            onChange={(event, value) => {
              handleFilterChange(event, "themes", value);
            }}
            value={sieve.filter.themes}
            autoHighlight
            multiple
            id="themeSelect"
            options={themeOptions}
            getOptionLabel={(option) => option.title}
            getOptionSelected={compareSelected}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Themes"
                variant="filled"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md>
          <Autocomplete
            onChange={(event, value) => {
              handleFilterChange(event, "category", value);
            }}
            value={sieve.filter.category}
            autoHighlight
            id="categorySelect"
            options={categoryOptions}
            getOptionLabel={(option) => option.title}
            getOptionSelected={compareSelected}
            renderInput={(params) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} label="Categories" variant="filled" />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
}

MediaSieve.defaultProps = {
  onChange: () => {},
  themeOptions: [],
  categoryOptions: [],
  initialSieve: {
    filter: null,
    sort: null,
  },
};

MediaSieve.propTypes = {
  onChange: PropTypes.func,
  themeOptions: PropTypes.arrayOf(themeType),
  categoryOptions: PropTypes.arrayOf(categoryType),
  initialSieve: sieveType,
};

export default MediaSieve;
