import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import MediaCard from "../MediaCard";
import { mediaType } from "../../types";
import NoContent from "../NoContent";

const useStyles = makeStyles(() => ({
  gridContainer: {
    listStyle: "none",
    paddingLeft: 0,
  },
}));

function MediaGrid({ media }) {
  const classes = useStyles();
  return media.length > 0 ? (
    <Grid
      container
      spacing={3}
      className={classes.gridContainer}
      component="ul"
    >
      {media.map((m, index) => {
        return (
          <Fade in key={m.id} style={{ transitionDelay: `${index * 50}ms` }}>
            <Grid item lg={4} sm={6} xs={12} component="li">
              <MediaCard media={m} to={`/media/${m.slug.current}`} />
            </Grid>
          </Fade>
        );
      })}
    </Grid>
  ) : (
    <NoContent text="No Media to Show" />
  );
}

MediaGrid.defaultProps = {
  media: [],
};

MediaGrid.propTypes = {
  media: PropTypes.arrayOf(mediaType),
};

export default MediaGrid;
