import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { format } from "date-fns";
import { mediaType } from "../../types";
import InlineList from "../InlineList";
import MediaImagePlaceholder from "../MediaImagePlaceholder";
import {
  getHeaderText,
  getThumbnail,
  inferMediaType,
  sortAlphabetically,
} from "../../lib/helpers";
import ThemeLink from "../ThemeLink";
import CategoryLink from "../CategoryLink";

const THUMBNAIL_HEIGHT = 200;

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    height: THUMBNAIL_HEIGHT,
  },
  thumbnailPlaceholder: {
    height: THUMBNAIL_HEIGHT,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
  },
  thumbnailPlaceholderIcon: {
    fontSize: theme.spacing(8),
  },
  contentSection: {
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function MediaCard({ media, to }) {
  const { title, themes, category, publishedAt } = media;
  const [header, subheader] = getHeaderText(title);
  const type = inferMediaType(media);
  const thumbnail = getThumbnail(media);
  const classes = useStyles();

  const titleNode = (
    <div className={classes.title}>
      <Typography variant="h5">{header}</Typography>
      <Typography variant="body2" color="textSecondary" component="span">
        {format(publishedAt, "MMMM Do, YYYY")}
      </Typography>
    </div>
  );

  const themeLinks = [...themes]
    .sort(sortAlphabetically)
    .map((theme) => <ThemeLink theme={theme} />);

  return (
    <Card elevation={4}>
      <CardHeader title={titleNode} subheader={subheader} />
      <CardActionArea component={GatsbyLink} to={to}>
        {thumbnail && thumbnail.url ? (
          <CardMedia
            alt={thumbnail.alt || ""}
            height={THUMBNAIL_HEIGHT}
            image={thumbnail.url}
            title={title}
            className={classes.thumbnail}
          />
        ) : (
          <MediaImagePlaceholder type={type} height={THUMBNAIL_HEIGHT} />
        )}
      </CardActionArea>

      <CardContent>
        {themes && Array.isArray(themes) && themes.length > 0 && (
          <section className={classes.contentSection}>
            <Typography variant="overline" component="h6" color="textSecondary">
              Themes
            </Typography>
            <InlineList items={themeLinks} />
          </section>
        )}

        {category && (
          <section className={classes.contentSection}>
            <Typography variant="overline" component="h6" color="textSecondary">
              Category
            </Typography>
            <CategoryLink category={category} />
          </section>
        )}
      </CardContent>
      <CardActions>
        <Button component={GatsbyLink} to={to} color="primary">
          View Media
        </Button>
      </CardActions>
    </Card>
  );
}

MediaCard.defaultProps = {
  to: null,
};

MediaCard.propTypes = {
  media: mediaType.isRequired,
  to: PropTypes.string,
};

export default MediaCard;
